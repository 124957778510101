import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.4;
  }
`;

const StyledCompassArrow = styled.svg`
  height: 8vh;
  max-height: 80px;
  animation: ${pulse} 4s linear infinite;
  transform: ${props => props.left && 'rotate(180deg)'};
  path {
    fill: ${props => props.color};
  }
  &:hover {
    animation: none;
    opacity: 0.8;
  }
  transition: all 0.5s;
`;

const CompassArrowSVG = ({ color, left }) => (
  <StyledCompassArrow
    id="backgroundAnchor"
    data-name="Compass Arrow"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34 28.43"
    color={color}
    left={left}
  >
    <title>compassStyleArrow</title>
    <path
      className="cls-1"
      d="M36.26,20,2.26,5.79,8.4,20,2.26,34.22ZM9.48,20H33.67L4.16,32.34Z"
      transform="translate(-2.26 -5.79)"
    />
  </StyledCompassArrow>
);

export default CompassArrowSVG;
