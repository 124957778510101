import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import CompassArrow from './CompassArrow';
import AnchorSVG from './AnchorSVG';
import CenteredDiv from './styles/CenteredDiv';
import NauticalChart from './backgrounds/NauticalChart';
import { Layout, SplitSection, Content } from './styles/Layout';
import AdditionalDetailsIcon from './AdditionalDetalsIcon';

export const spiritData = {
  gin: {
    preText: 'San Diego County',
    name: 'Gin',
    subText: 'Small Batch',
    proof: 90,
    bottleSize: 750,
    description:
      'There is no other spirit that has been responsible for more classic cocktails than Gin. Dating back to the 17th century, Gin originated in Holland and the golden age of the martini was born. Liberty Call Distilling has proudly crafted a bright and refreshing blend using evaporated cane juice, vapor infused Juniper, coriander, five aromatic flowers, zest of three citruses, and its secret blend of botanicals to create an evolved and modern twist on Gin. The distilling process constructs complex floral tasting notes of lavender, chamomile, layered herbs, hint of juniper, and zest of three citruses which generates the refreshing citrus finish. If that doesn’t sound like summertime freshness, we don’t know what does!',
    image: 'gin',
    color: '19, 20, 61',
    msrp: 30,
    soldOut: false,
  },
  spicedRum: {
    preText: 'San Diego County',
    name: 'Spiced Rum',
    subText: 'Small Batch',
    proof: 90,
    bottleSize: 750,
    description:
      'Fine spirits take time and our passion is to produce Spiced Rum with a complex mix of flavors that is like none other. Redistilling the rum and loading it with a botanical basket of eight spices such as cloves, cinnamon, peppercorns and nutmeg has become our recipe for great tasting rum. By refilling our botanical basket every couple of gallons, we can guarantee a smooth but bold experience in every sip. Aging with charred oak allows us to darken the spiced rum without adding too much caramelized sugar. We then splash in hand-made caramel, resulting in toffee and burnt sugar notes, which gives it the refined flavors of sophisticated class. Our spiced rum promises to be bold & spicy, but definitely not overly sweet.',
    image: 'spicedRum',
    color: '150, 55, 21',
    msrp: 30,
    soldOut: false,
  },
  whiteRum: {
    preText: 'San Diego County',
    name: 'White Rum',
    subText: 'Navy Strength',
    proof: 90,
    bottleSize: 750,
    description:
      'The mention of sandy beaches of the Caribbean Islands brings to mind the perfect fruity white rum cocktail. Rum was discovered when molasses became a byproduct of the sugar refining process, then fermented it into alcohol. Liberty Call Distilling takes great pride in incorporating those traditional values and mixes it with evaporated cane juice, the cleanest form of raw sugar, then combines it with wine yeast and a proprietary dunder. The lasting flavor is both fruity and nutty, reminiscent of tropical fruits, berries, hazelnut and almond. Complimenting the smooth finish is a burst of bananas, strawberry, and apricots that round out the tropical experience.',
    image: 'whiteRum',
    color: '175, 130, 75',
    msrp: 30,
    soldOut: false,
  },
  oldIronsides: {
    name: 'Old Ironsides',
    subText: 'American Single Malt',
    proof: 90,
    bottleSize: 375,
    description:
      'Keeping in line with American tradition, our distillers have mastered the arduous task of creating a uniquely smooth Single Malt Whiskey that has come to be the Liberty Call Distilling trademark of quality. Our production follows strict procedures to adhere to the American Single Malt Whiskey Commission’s Standard of Identity. Using 100% barley mash, a secret Red Ale recipe leads the flavor with a variety of caramel malts and Maris Otter, producing a rich biscuit like sweetness. As we prepare the Single Malt Whiskey, it is aged in a used whiskey oak cask, leaving behind a hint of cereal and creamy caramel. Each sip leads with bright notes of chocolate fudge and stone fruit, and finishes with a slightly spicy yet rich, toasty caramel and chocolate sensation. To fully enjoy the complexity of this rare whiskey, it is recommended sipping it neat in a quality snifter glass to heighten its zesty aroma.',
    image: 'oldIronsides',
    color: '25, 86, 152',
    msrp: 40,
    soldOut: false,
  },
  blueRidge: {
    name: 'Blue Ridge',
    subText: 'California Four Grain Whiskey',
    proof: 90,
    bottleSize: 375,
    description:
      'We take a No-Shortcuts approach to the craftsmanship of our 4 Grain Whiskey to ensure that the robust flavors are aged to perfection. As a tribute to San Diego’s microbrews, Liberty Call Distilling has married the idea of using a California ale yeast and the traditional 4 grains of corn, wheat, rye and barley to round out the sturdy taste. We age our 4 Grain Whiskey using the Solera Method generally reserved for fine Cognacs and Brandies. The whiskey is placed in heavy char white American oak barrels with additional birch and cherry staves to coax out rich and enticing flavors. The pleasing aroma presents a bouquet of oak, toffee and vanilla. The extremely smooth taste leaves lingering notes of butterscotch and cherry, perfect for an after dinner drink.',
    image: 'blueRidge',
    color: '42, 42, 42',
    msrp: 35,
    soldOut: false,
  },
  constitutionBourbon: {
    name: 'Constitution Bourbon',
    subText: 'Blended Bourbon Whiskey',
    proof: 86,
    bottleSize: 750,
    description: `A beautiful blend of our amercican bourbon and our unique rye whiskey distilled from chocolate toasted rye. Aged in new, heavily charred White American Oak barrels for big oak and vanilla flavor.
      Amazingly easy to drink, this bourbon hits the nose with a bouquet of oak, maple, and vanilla. Every sip leads with rich layers of mocha flavor, and finishes with a lingering maple sweetness on the tongue. `,
    image: 'constitutionBourbon',
    color: '79, 42, 26',
    msrp: 35,
    soldOut: false,
  },
  doubleWood: {
    name: 'Special Reserve DoubleWood Bourbon',
    subText: 'Very Small Batch',
    proof: 90,
    bottleSize: 750,
    description:
      'We take great pride in creating that exceptional taste of our Liberty Call Double Wood Bourbon. First aged in a new 53 gallon medium charred American oak barrel, then moved into a new 5 gallon medium charred American oak barrel. Creating a softening effect by exposing the bourbon to 10 times the wood of a 53 gallon barrel, this mellows the tannins and brings out notes of vanilla and caramel, for a truly great Bourbon.',
    image: 'doubleWood',
    color: '184, 114, 17',
    msrp: 199,
    soldOut: false,
  },
  bulletBottle: {
    name: '.50 Caliber DoubleWood Bourbon',
    subText: 'Special Reserve',
    proof: 90,
    bottleSize: 750,
    description:
      'This custom Sand Carved Liberty Style Bottle has a .50 caliber bullet mouonted in it and can be customized to your personal specifications, adding the ability to add personal name and company to the bottle. Filled with our Double Wood Bourbon that is first aged in a new 53 gallon medium charred American oak barrel, then moved into a new 5 gallon medium charred American oak barrel. Creating a softening effect by exposing the bourbon to 10 times the wood of a 53 gallon barrel, this mellows the tannins and brings out notes of vanilla and caramel, for a truly great Bourbon.',
    image: 'bulletBottle',
    color: '19, 20, 61',
    msrp: 249,
    soldOut: false,
  },
  constitutionStraightBourbon: {
    name: 'Constitution Straight Bourbon',
    subText: 'Limited Edition',
    bottleSize: 750,
    proof: 90,
    image: 'constitutionBourbonSandCarved',
    description:
      'Special Straight 2 Year Old Blend of our American bourbon and our unique rye whiskey distilled from chocolate toasted rye. This is even smoother and bolder than or our original Constitution Bourbon. Aged in new, heavily charred White American Oak barrels for big oak and vanilla flavor. Amazingly easy to drink, this bourbon hits the nose with a bouquet of oak, maple, and vanilla. Every sip leads with rich layers of mocha flavor, and finishes with a lingering maple sweetness on the tongue.',
    color: '66, 97, 52',
  },
  oldIndependence: {
    name: 'Old Independence',
    subText: 'Owners Private Reserve 24',
    bottleSize: 750,
    proof: 90,
    image: 'oldIndependence',
    description:
      'The best of the best. For this limited release, we hand picked our very favorite barrels of our Special Reserve DoubleWood Bourbon to create an even more exceptional whiskey. The cream of the crop barrels give it the boldest flavor and smoothest finish.',
    color: '0, 33, 163',
  },
};

const SpiritsSplitSection = styled(SplitSection)`
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    .contentSide {
      position: absolute;
      background: white;
      padding: 4rem 2rem;
    }
    grid-template-rows: 1fr;
    grid-template-areas: 'imageSide';
  }
`;

const bottleAnimation = keyframes`
  0% {
    transform: translateX(40vw) rotate(-15deg);
    opacity: 0;
  }
  60% {
    transform: translateX(0vw) rotate(15deg);
    opacity: 0.8;
  }
  80% {
    transform: translateX(0vw) rotate(-5deg);
    opacity: 1;
  }
  90% {
    transform: translateX(0vw) rotate(3deg);
    opacity: 1;
  }
  100% {
    transform: translateX(0vw) rotate(0deg);
    opacity: 1;
  }
`;

const AnimatedBottle = styled.img`
  height: 80vh;
  max-height: 760px;
  z-index: 0;
  margin: 0;
  animation: ${bottleAnimation} 0.8s cubic-bezier(0.42, 0, 0.13, 1);
  transform: rotate(0deg);
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    height: 75vh;
    margin-bottom: 5vh;
  }
`;

const FeaturedBottle = ({ index, bottleImages }) => {
  const [spirit, setSpirit] = useState(Object.values(spiritData)[0]);
  const [imgSrc, setImgSrc] = useState(
    bottleImages[Object.keys(spiritData)[0]]
  );

  useEffect(() => {
    setImgSrc(
      bottleImages[spiritData[Object.keys(spiritData)[index]].image].fluid.src
    );
    setSpirit(Object.values(spiritData)[index]);
  }, [index]);

  return (
    <CenteredDiv>
      <AnimatedBottle key={imgSrc} src={imgSrc} />
      <AnchorSVG color={spirit.color} />
    </CenteredDiv>
  );
};

const FeaturedContent = ({ index }) => {
  const data = Object.values(spiritData)[index];
  return (
    <Content className="content">
      <div className="details">
        <hr />
        <h4 className="title" style={{ margin: 0 }}>
          {data.subText}
        </h4>
        <h3
          className="title"
          style={{ color: `rgb(${data.color})`, margin: '2rem 0' }}
        >
          {data.name}
        </h3>
        <p className="cocktailDirections">{data.description}</p>
      </div>
    </Content>
  );
};

const NextButton = styled.div`
  position: absolute;
  margin: 0;
  bottom: 2rem;
  height: min-content;
  right: 2rem;
  color: ${props => props.theme.navy()};
  font-size: 80px;
  font-weight: 700;
  z-index: 3;
  cursor: pointer;
  border: none;
  display: ${props => (props.hidden ? 'none' : 'block')};
`;

const StyledAdditionalDetailsIcon = styled(AdditionalDetailsIcon)`
  position: absolute;
  margin: 0;
  bottom: 2rem;
  left: 2rem;
  font-weight: 700;
  z-index: 3;
  cursor: pointer;
  border: none;
  opacity: 0;
`;

const MobileDropDown = styled.div`
  padding: 0 7vw;
  line-height: 1.5em;
  margin: auto;
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    transform: translateY(${props => (props.down ? 0 : '-100%')});
    transition: all 0.4s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    padding: 0 7vw 20vh 7vw;
    opacity: ${props => (props.down ? 1 : 0)};
  }
`;

const SpiritsSection = ({ bottleImages }) => {
  const [featuredIndex, setFeaturedIndex] = useState(5);
  const [displayDetails, setDisplayDetails] = useState(false);
  const handleNext = () => {
    const next = () => {
      if (featuredIndex === Object.keys(spiritData).length - 1) {
        setFeaturedIndex(0);
      } else {
        setFeaturedIndex(featuredIndex + 1);
      }
    };
    if (displayDetails) {
      setDisplayDetails(false);
      setTimeout(next, 400);
    } else next();
  };
  return (
    <>
      <Layout>
        <SpiritsSplitSection>
          <NauticalChart
            className="imageSide"
            overlayColor="white"
            overlayOpacity="0.95"
          >
            <FeaturedBottle bottleImages={bottleImages} index={featuredIndex} />
          </NauticalChart>
          <MobileDropDown down={displayDetails}>
            <FeaturedContent index={featuredIndex} />
            <NextButton hidden={!displayDetails} onClick={handleNext}>
              <CompassArrow color="#13143d" />
            </NextButton>
            <StyledAdditionalDetailsIcon
              hidden={!displayDetails}
              onClick={() => setDisplayDetails(!displayDetails)}
            />
          </MobileDropDown>
        </SpiritsSplitSection>
      </Layout>
      <NextButton hidden={displayDetails} onClick={handleNext}>
        <CompassArrow color="#13143d" />
      </NextButton>
      <StyledAdditionalDetailsIcon
        hidden={displayDetails}
        onClick={() => setDisplayDetails(!displayDetails)}
      />
    </>
  );
};

const SpiritsSectionWrapper = () => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxHeight: 800, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const imagesObject = {};
      const images = data.images.edges.filter(n => {
        return n.node.relativePath.includes('bottleImages'); // all files inside the bottleImages folder
      });
      images.forEach(n => {
        imagesObject[n.node.name] = n.node.childImageSharp;
      });
      return <SpiritsSection bottleImages={imagesObject} />;
    }}
  />
);

export default SpiritsSectionWrapper;
