import React from 'react';
import Page from '../components/Page';
import Spirits from '../components/Spirits';
import WipeTransition from '../components/WipeTransition';

const SpiritsPage = props => (
  <Page
    transitionStatus={props.transitionStatus}
    transitionDirection="right"
    lightBackground
  >
    <meta name="theme-color" content="#000000" />
    <Spirits />
    {props.transitionStatus === 'entering' && (
      <WipeTransition animation="finishLeftToRight" />
    )}
  </Page>
);

export default SpiritsPage;
